<template>
 <section id="login-page">
        <div class="container-fluid m-0 p-0">
            <div class="login-top">
                <div class="row" style="margin-right:0px; margin-left:0px;">
                    <div class="col-md-12">
                        <div class="main-logo-title">
                            <img src="../assets/images/recopedia-white.png" alt="logo" width="200">
                        </div>
                        <h5 class="text-white text-center">Lupa Password</h5>
                        <div class="text-center text-white mt-3 mb-4 mx-auto">
                            <h6>Masukkan alamat email Anda yang terdaftar untuk<br> me-reset password</h6>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="login-card">
                            <div class="card mx-auto p-4 shadow">
                                <!-- Form Login -->
                                <form action="#" id="login-form">
                                   <div class="alert " v-bind:class="[notif_alert.alertType]" role="alert" v-bind:style={display:notif_alert.alert}>
                                      {{notif_alert.alertMessage}}
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="email" class="mb-2">Email</label>
                                        <input type="text" v-model="email" :readonly="isRead" class="form-control" id="email" placeholder="Masukkan Email">
                                    </div>
                                    
                                    <div class="d-grid my-3">
                                        <button v-if="load" class="btn btn-main-login py-2" disabled><i class="fa fa-spinner fa-spin"></i> KIRIM SEKARANG</button>
                                        <button v-else v-on:click="onSubmit()" class="btn btn-main-login py-2">KIRIM SEKARANG</button>
                                    </div>
                                    <a href="#" class="mt-4 link-help">Anda sudah punya akun? silahkan <span class="fw-bold"><router-link to="/login">Masuk Disini</router-link></span></a>
                                </form>
                                <!-- End Form Login -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center mt-3 pt-5 fw-bold mb-5">
                        <footer>
                             <img src="../assets/images/anhi-logo-OG.png" style="width:150px;"/>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";

export default {
  name: "ForgotPassword",
  data() {
    return {
      load:false,
      email: "",
      error: null,
      message: null,
      isRead:false,
      notif_alert:{
          alert:"none",
          alertMessage:"",
          alertType:"",
      },
    };
  },
  created(){
    document.title = 'Lupa Password - Recopedia'
  },
  methods: {
     async onSubmit() {
          this.load = true
          this.isRead = true
          this.notif_alert.alertType =""
          this.notif_alert.alert="none"
          this.notif_alert.alertMessage= ""

          const payload = {
              email:this.email,
              type:"dokter",
          };
          await AuthService.forgotPassword(payload)
          .then((response) => this.onResponse(response))
          .catch((error) => this.onError(error))
     },
     onError(error) {
        this.notif_alert.alertType ="alert-danger"
        this.notif_alert.alert="block"
        this.notif_alert.alertMessage= getError(error)
        this.load = false
        this.isRead = false
     },
     onResponse(response) {
        if(response.data.result == "error"){
          this.notif_alert.alertType ="alert-danger"
          this.notif_alert.alert="block"
          this.notif_alert.alertMessage= response.data.message
        } else {
          this.notif_alert.alertType ="alert-success"
          this.notif_alert.alert="block"
          this.notif_alert.alertMessage= response.data.message
        }
        this.load = false
        this.isRead = false
     }
  },
};
</script>
